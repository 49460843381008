import React from 'react';
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area } from 'recharts';

import { round_digits } from "utils/utils";


function CustomizedAxisTick({ x, y, payload }) {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform='rotate(-15)'>
                {new Date(payload.value).toDateString()}
            </text>
        </g>
    );
}


export default function ScoreGraph({score_series}) {
    const dates_by_order = Object.keys(score_series).sort();

    const start_time = new Date(dates_by_order[0]).getTime();
    const end_time = new Date(dates_by_order[dates_by_order.length - 1]).getTime();
    const time_window_size = 1*24*60*60*1000; // 1 day
    const num_of_parts = Math.round((end_time - start_time) / time_window_size);
    console.log('num_of_parts', num_of_parts);
    
    const time_series = new Array(num_of_parts).fill(0);
    for (let date of dates_by_order) {
        const window_index = Math.round((new Date(date).getTime() - start_time) / time_window_size)
        time_series[window_index] = score_series[date];
    }

    const data = [];
    for (let i = 0; i < time_series.length; i++) {
        if (time_series[i] == 0) {
            time_series[i] = time_series[i - 1] || 0;
        }

        // If we have no data, and the first score is not 0, add a 0 score
        if (data.length == 0 && time_series[i] > 0) {
            data.push({time: start_time-time_window_size, score: 0});
        }

        data.push({time: start_time + i * time_window_size, score: round_digits(time_series[i], 2)});
    }

    return (
        <div className="score_graph">
            <h2>Resilience Score</h2>

            <AreaChart width={1000} height={600} data={data}
                margin={{ top: 10, right: 40, left: 40, bottom: 100 }}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#198754" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#198754" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#e91e63" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#e91e63" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <XAxis dataKey="time" scale="time" type="number" domain={['dataMin', 'dataMax']} tick={<CustomizedAxisTick />} />
                <YAxis domain={[0, 100]} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip labelFormatter={value => new Date(value).toDateString()} />
                <Area type="monotone" dataKey="score" stroke="#198754" fillOpacity={1} fill="url(#colorUv)" />
            </AreaChart>

        </div>
    );
}