import React, { useState, useEffect, useRef, useMemo, Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import MitreMatrix from 'components/MitreMatrix.jsx';
import Controls from 'components/Controls.jsx';
import Policies from 'components/Policies.jsx';
import PolicySidePanel from 'components/PolicySidePanel.jsx';
import CustomRangeSlider from 'components/CustomRangeSlider.jsx';
import API from 'utils/api';

import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { useWindowSize } from 'utils/utils';
import { CONTROLS } from 'consts.js';

import { UserContext } from 'main.jsx';
import { Permissions } from 'enums.js';

export default function NewPoliciesPage() {
    const [error, set_error] = useState(null);
    const [is_loaded, set_is_loaded] = useState(false);
    const [pending_policies, set_pending_policies] = useState([]);
    const [mitre_matrix_weights, set_mitre_matrix_weights] = useState({});

    const [hovered_control, set_hovered_control] = useState(''); // TODO: delete
    const [selected_control, set_selected_control] = useState(''); // TODO: replace with url

    const [hovered_policy, set_hovered_policy] = useState(-1);
    const [selected_policy, set_selected_policy] = useState(-1);

    const [show_generation_modal, set_show_generation_modal] = useState(false);
    const [is_generating, set_is_generating] = useState(false);
    const [is_approving, set_is_approving] = useState(false);
    const [generate_for_control, set_generate_for_control] = useState('');
    const [minimum_group_size, set_minimum_group_size] = useState(50);

    const [window_width, window_height] = useWindowSize();
    const policiesRef = useRef(null);

    const {user} = useContext(UserContext);

    useEffect(() => {
        let cancelled = false;

        API.get_pending_policies()
        .then(
            (result) => {
                const pending_policies = result.policies;

                if (!cancelled) {
                    set_mitre_matrix_weights(result.theme_weights);
                    set_pending_policies(pending_policies);
                    set_is_loaded(true);
                }
            },
            (error) => {
                if (!cancelled) {
                    set_is_loaded(true);
                    set_error(error);
                }
            }
        )

        return () => {
            cancelled = true;
        };
    }, []);

    const matrix_protections = useMemo(() => {
        const res = {};
        // for each control in policies, calculate the average protection for each technique
        for (const control in pending_policies) {
            console.log(typeof pending_policies[control], pending_policies[control].reduce, pending_policies[control]);
            const total_entities = pending_policies[control].reduce((acc, policy) => acc + policy.entities.length, 0);

            const matrix_protection_of_control = res[control] = {};

            for(const policy of pending_policies[control]) {
                const factor = policy.entities.length / total_entities;
                for(const technique in policy.matrix_protection) {
                    if (matrix_protection_of_control[technique] === undefined) {
                        matrix_protection_of_control[technique] = 0;
                    }
                    matrix_protection_of_control[technique] += policy.matrix_protection[technique] * factor;
                }
            }
        }
        
        return res;
    }, [pending_policies]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!is_loaded) {
        return <div>Loading...</div>;
    } else {
        let matrix_protection = {};
        if (hovered_policy >= 0) {
            matrix_protection = pending_policies[selected_control][hovered_policy].matrix_protection;
        }
        else if (selected_policy >= 0) {
            matrix_protection = pending_policies[selected_control][selected_policy].matrix_protection;
        }
        else if (hovered_control) {
            matrix_protection = matrix_protections[hovered_control];
        }
        else if (selected_control) {
            matrix_protection = matrix_protections[selected_control];
        }

        function generate_new_policies() {
            if (generate_for_control === '') {
                // TODO: replace with form validation
                alert('Please select a control');
                return;
            }
        
            set_is_generating(true);
            API.generate_policies(generate_for_control, parseInt(minimum_group_size))
                .then(
                    (result) => {
                        console.log('Generated policies', result);
                        
                        set_is_generating(false);
                        set_show_generation_modal(false);

                        set_pending_policies(pending_policies => ({
                            ...pending_policies,
                            [generate_for_control]: result[generate_for_control]
                        }));

                        // Set focus on the newly generated policies
                        set_selected_control(generate_for_control);
                    },
                    (error) => {
                        console.error(error);
                        // TODO: show the error to the user

                        set_is_generating(false);
                        set_show_generation_modal(false);
                    }
                )
            ;
        }

        function cancel_generation_modal() {
            set_show_generation_modal(false);
        }

        function approve_policies() {
            set_is_approving(true);
            API.approve_policies(selected_control).then(
                (result) => {
                    console.log('TODO: policies approved', result);

                    set_is_approving(false);
                },
                (error) => {
                    console.error('TODO: handle error in policies approval', error);

                    set_is_approving(false);
                }
            )
        }

        const ref_top = policiesRef.current?.getBoundingClientRect()?.y;
        const height = ref_top ? window_height - ref_top : 0;

        return (
            <Fragment>
                <Helmet>
                    <title>Libra - {selected_control ? `New ${selected_control} Policies`: 'New Policies'}</title>
                </Helmet>
                <Col className='bg-dark text-bg-dark' md='3' >
                    <Controls selected_control={selected_control} policies={pending_policies} controls={CONTROLS} onSelect={set_selected_control} />
                    <Button variant="success" className='m-2' onClick={e => set_show_generation_modal(true)} disabled={!user.permissions.includes(Permissions.CREATE_POLICIES)}>
                        Generate New Policies
                    </Button>
                    {['wdac', 'asr_block_office_child_process', 'asr_block_office_communication_child_process', 'asr_block_adobe_child_process'].includes(selected_control)/* TODO: take this list from the server */ && <Button className='m-2 approve_policies' variant='outline-success' size='sm' onClick={approve_policies} disabled={!user.permissions.includes(Permissions.CREATE_POLICIES)}>
                        {is_approving && <span>Approving Policies <FontAwesomeIcon icon={faSpinner} spin /></span>}
                        {!is_approving && <span>Approve Policies <FontAwesomeIcon icon={faCheck} /></span>}
                    </Button>}
                    <div ref={policiesRef}></div>
                    {(selected_control && <Policies 
                                            height={height}
                                            policies={pending_policies[selected_control]}
                                            onSelect={set_selected_policy}
                                            onHover={set_hovered_policy}
                                            hovered_policy_index={hovered_policy}
                                            selected_policy_index={selected_policy}
                                        />)}
                </Col>
                <Col className='mt-4' md='8'>
                    <MitreMatrix
                        weights={mitre_matrix_weights}
                        percentage={matrix_protection}
                    />
                </Col>
                {(selected_policy >= 0 && <PolicySidePanel control={selected_control} policy={pending_policies[selected_control][selected_policy]} onClose={() => set_selected_policy(-1)} />)}

                <Modal show={show_generation_modal} onHide={cancel_generation_modal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Generate New Policies</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Control</Form.Label>
                                <Form.Select autoFocus onChange={e => set_generate_for_control(e.target.value)} value={generate_for_control}>
                                    <option value="">Select a control</option>
                                    {CONTROLS.map(({name, id}) => <option key={id} value={id}>{name}</option>)}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Minimal group size</Form.Label>
                                <CustomRangeSlider
                                    value={minimum_group_size}
                                    onChange={new_value => {return set_minimum_group_size(new_value)}}
                                    tooltipPlacement='bottom'
                                    tooltip='on'
                                    ranges={[{start: 0, end: 10, step: 2}, {start: 10, end: 100, step: 10}, {start: 100, end: 500, step: 50}]}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={cancel_generation_modal}>
                            Cancel
                        </Button>
                        <Button variant="success" onClick={generate_new_policies}>
                            {is_generating && <span>Generating <FontAwesomeIcon icon={faSpinner} spin /></span>}
                            {!is_generating && <span>Generate</span>}
                        </Button>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
};