import React from 'react';

import DashboardPage from './DashboardPage.jsx';
import NewPoliciesPage from './NewPoliciesPage.jsx';
import PoliciesEnrollmentPage from './PoliciesEnrollmentPage.jsx';
import LogPage from './LogPage.jsx';
import SelfServicePage from './SelfServicePage.jsx';
import FeaturePage from './FeaturePage.jsx';

import { Permissions } from 'enums.js';

const pages = [
    {path: '/', title: 'Dashboard', element: <DashboardPage />, required_permissions: []},
    {path: '/new_policies', title: 'New Policies', element: <NewPoliciesPage />, required_permissions: [Permissions.READ_POLICIES, Permissions.CREATE_POLICIES]}, // TODO: remove the create policies permission
    {path: '/policies_enrollment', title: 'Policies Enrollment', element: <PoliciesEnrollmentPage />, required_permissions: [Permissions.READ_POLICIES, Permissions.CREATE_POLICIES]},  // TODO: remove the create policies permission after deploying Intune service
    {path: '/self_service', title: 'Self Service', element: <SelfServicePage />, required_permissions: [Permissions.READ_POLICIES]},
    {path: '/log', title: 'Log', element: <LogPage />, required_permissions: [Permissions.READ_POLICIES]},
    {path: '/feature', title: 'Feature', element: <FeaturePage />, required_permissions: [Permissions.ADMIN_ONLY]},
];

export function get_pages(user) {
    return pages.filter(({required_permissions}) => required_permissions.every((required_permission) => user.permissions.includes(required_permission)));
}