import React, { Fragment, StrictMode, createContext, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { get_pages } from 'pages/pages.jsx';
import PageLayout from 'pages/PageLayout.jsx';
import NotFoundPage from 'pages/NotFoundPage.jsx';
import LoginPage from 'pages/LoginPage.jsx';


export const UserContext = createContext(null);

function App() {
    const [user, setUser] = useState(logged_in_user);

    return (
        <StrictMode>
            <HelmetProvider>
                <UserContext.Provider value={{ user: user, setUser: setUser }}>
                    <HashRouter>
                        <Routes>
                            {/* TODO: lazy load the pages. https://react.dev/reference/react/lazy */}
                            {(user !== null) ? (
                                <Fragment>
                                    <Route path="/" element={<PageLayout />}>
                                        {get_pages(user).map(({path, element}) => <Route key={path} path={path} element={element} />)}
                                    </Route>
                                    <Route path="*" element={<NotFoundPage />} />
                                </Fragment>
                            ) : (
                                <Route path="*" element={<PageLayout />}>
                                    <Route path="*" element={<LoginPage />} />
                                </Route>
                            )}
                        </Routes>
                    </HashRouter>
                </UserContext.Provider>
            </HelmetProvider>
        </StrictMode>
    );
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);