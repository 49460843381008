import React from 'react';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShield, faBug } from '@fortawesome/free-solid-svg-icons'

export default function MitreMatrix({ weights, percentage}) {
    // TODO: add all techniques into the matrix
    const caterogies = ['Initial Access', 'Execution', 'Credential Access', 'Discovery', 'Lateral Movement', 'Collection', 'C&C', 'Exfiltration', 'Impact', 'Defense Evasion'];    
    const techniques = {
        'T1047': 'Windows Management Instrumentation',
        'T1021.001': 'Remote Services: Remote Desktop Protocol',
        'T1021.002': 'Remote Services: SMB/Windows Admin Shares',
        'T1570': 'Lateral Tool Transfer',
        'T1135': 'Network Share Discovery',
        'T1059.007': 'Command and Scripting Interpreter: JavaScript',
        'T1059.005': 'Command and Scripting Interpreter: Visual Basic',
        'T1218.005': 'System Binary Proxy Execution: Mshta',
        'T1218.011': 'System Binary Proxy Execution: Rundll32',
        'T1218.010': 'System Binary Proxy Execution: Regsvr32',
        // Removed for now: 'T1218.007': 'System Binary Proxy Execution: Msiexec',
        'T1007': 'System Service Discovery',
        'T1490': 'Inhibit System Recovery',
        'T1489': 'Service Stop',
        'T1569.002': 'System Services: Service Execution',
        'T1083': 'File and Directory Discovery',
        'T1082': 'System Information Discovery',
        'T1057': 'Process Discovery',
        'T1566.001': 'Phishing: Spearphishing Attachment',
        'T1203': 'Exploitation for Client Execution',
        'T1112': 'Modify Registry',
        'T1012': 'Query Registry',
        'T1552.002': 'Unsecured Credentials: Credentials in Registry',
        'T1053.005': 'Scheduled Task/Job: Scheduled Task',
        'T1140': 'Deobfuscate/Decode Files or Information',
        'T1560.001': 'Archive Collected Data: Archive via Utility',
        'T1105': 'Ingress Tool Transfer',
        'T1553.004': 'Subvert Trust Controls: Install Root Certificate',
        'T1197': 'BITS Jobs',
        'T1048.003': 'Exfiltration Over Alternative Protocol: Exfiltration Over Unencrypted Non-C2 Protocol',
        'T1202': 'Indirect Command Execution',
        'T1127.001': 'Trusted Developer Utilities Proxy Execution: MSBuild',
    };
    const categories_techniques = {
        'Initial Access': ['T1566.001'],
        'Execution': ['T1059.005', 'T1059.007', 'T1203', 'T1569.002', 'T1053.005', 'T1047'],
        'Defense Evasion': ['T1197', 'T1140', 'T1112', 'T1127.001', 'T1202', 'T1553.004', 'T1218.005', /* Removed for now: 'T1218.007', */ 'T1218.010', 'T1218.011'],
        'Credential Access': ['T1552.002'],
        'Discovery': ['T1083', 'T1135', 'T1057', 'T1012', 'T1082', 'T1007'],
        'Lateral Movement': ['T1570', 'T1021.001', 'T1021.002'],
        'Collection': ['T1560.001'],
        'C&C': ['T1105'],
        'Exfiltration': ['T1048.003'],
        'Impact': ['T1490', 'T1489'],
    };

    const max_weight = Math.max(...Object.values(weights));
    const epsilon = 0.01;

    return (
        <div className='container MitreMatrix'>
            <div className="row">
                {caterogies.map(category => {
                    return (
                        <div key={category} className='col px-0'>
                            <span className='p-2 bg-light border rounded'>{category}</span>
                            <ul className='list-group'> {/* TODO: use react list group */}
                                {categories_techniques[category].map(technique => { 
                                    const p = (percentage[technique] !== undefined ? percentage[technique] : 0);
                                    const risk_tier = parseInt(weights[technique] * 3 / (max_weight+epsilon)) + 1;
                                    
                                    return (
                                        <li
                                            className='list-group-item text-dark me-1'
                                            key={technique}>
                                                <span>{techniques[technique]}</span>
                                                <div>
                                                    <Badge className='float-end mt-1' bg='danger'>
                                                        {[...Array(risk_tier)].map((e, i) => <FontAwesomeIcon key={i} icon={faBug} />)}
                                                    </Badge>
                                                    {percentage[technique] ? <Badge className='float-end m-1' bg='success'>
                                                        {parseInt(percentage[technique] * 100)}%
                                                        &nbsp;<FontAwesomeIcon icon={faShield} />
                                                    </Badge> : null}
                                                </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}