import React, { useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';

export default function PolicySidePanel({control, policy, onClose}) {
    console.log('policy', policy);

    return (
      <Offcanvas show={true} onHide={onClose} placement="end">
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>{policy.name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <h5>Configuration</h5>
            <ul>
                {(control == 'wdac') && policy.config.blocked_executables.sort().map(exe => <li key={`exe ${exe}`}>Block {exe}</li>)}
                {(control == 'firewall') && (
                    policy.config.blocked_ports.sort((a,b) => a[0] - b[0]).map(([port, allowed_ips]) => (
                        <li key={`port ${port}`}>
                            Block {port}
                            {allowed_ips.length > 0 && (
                                <ul>
                                    {allowed_ips.sort().map(ip => 
                                        <li style={{fontSize: '0.9em'}} key={`ip ${ip}`}>{ip}<div style={{fontSize: '0.86em'}}>{policy.config.allowed_ips_info[ip]}</div></li>
                                    )}
                                </ul>
                            )}
                        </li>
                    ))
                )}
                {(control == 'asr_block_office_child_process') && (
                    <li>{policy.config.enable ? 'Block Office applications from creating child processes' : 'Allow Office applications to create child processes'}</li>
                )}
                {(control == 'asr_block_office_communication_child_process') && (
                    <li>{policy.config.enable ? 'Block Outlook from creating child processes' : 'Allow Outlook to create child processes'}</li>
                )}
                {(control == 'asr_block_adobe_child_process') && (
                    <li>{policy.config.enable ? 'Block Adobe Reader from creating child processes' : 'Allow Adobe Reader to create child processes'}</li>
                )}

                {(policy.config.exclusions) && (
                    policy.config.exclusions.sort().map(exclusion =><li key={`exclusion ${exclusion}`}>Except {exclusion}</li>)
                )}
            </ul>
            <h5>Entities</h5>
            <ul>
                {policy.entities.map(entity => <li key={entity}>{entity}</li>)}
            </ul>
        </Offcanvas.Body>
      </Offcanvas>
    );
}