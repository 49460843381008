import React, { Fragment, useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import API from 'utils/api';
import { UserContext } from 'main.jsx';

import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export default function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [is_logging_in, set_is_logging_in] = useState(false);

    const {setUser} = useContext(UserContext);
    
    function do_login() {
        set_is_logging_in(true);

        API.login(username, password)
            .then(
                (result) => {
                    if (result.status == 'success') {
                        console.log('Login successfully', result);

                        set_is_logging_in(false);
                        setUser(result.user);
                    }
                    else {
                        console.error('Login failed', result);

                        set_is_logging_in(false);
                        alert('Login failed'); // TODO: show a better indication
                    }
                },
                (error) => {
                    console.error(error);
                    // TODO: show the error to the user

                    set_is_logging_in(false);
                }
            );
    }

    return (
        <Fragment>
            <Helmet>
                <title>Libra - Login</title>
            </Helmet>
            <Col className='bg-dark text-bg-dark' md='2' ></Col>
            <Col md='3' >
                <Form onSubmit={e => { e.preventDefault(); do_login(); }}>
                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" placeholder="<enter username>" value={username} onChange={e => setUsername(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder='<enter password>' value={password} onChange={e => setPassword(e.target.value)} />
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={do_login}>
                        Login {is_logging_in && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                    </Button>
                </Form>
            </Col>
        </Fragment>
    );
}