import React, { useMemo } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';


export default function CustomRangeSlider({value, ranges, onChange, ...props}) {
    const {ranges_index_to_value, ranges_value_to_index} = useMemo(() => {
        // For each range, for each value in the range, map it from its index to the actual value
        const ranges_index_to_value = {}, ranges_value_to_index = {};
        let index = 0;
        let prev_value = null;
        for (const {start, end, step} of ranges) {
            for (let value = start; value <= end; value += step) {
                // Ignore overlapping values between ranges
                if (value == prev_value) {
                    continue;
                }
                ranges_index_to_value[index] = value;
                ranges_value_to_index[value] = index;
                index++;
                prev_value = value;
            }
        }

        return {ranges_index_to_value, ranges_value_to_index};
    }, [ranges]);

    // TODO: assert the following are not in props: min, max, step, tooltipLabel

    return (
        <RangeSlider
            value={ranges_value_to_index[value]}
            onChange={e => {return onChange(ranges_index_to_value[e.target.value])}}
            min={0}
            step={1}
            max={Object.keys(ranges_index_to_value).length - 1}
            tooltipLabel={value => ranges_index_to_value[value]}
            {...props}
        />
    );
}