import React, { useState, Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import Offcanvas from 'react-bootstrap/Offcanvas';

import API from 'utils/api';

import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';


export default function SelfServicePage() {
    const [policies, set_policies] = useState({});
    const [self_service, set_self_service] = useState({});

    const [device_name_input, set_device_name_input] = useState('');
    const default_date = new Date();
    default_date.setDate(new Date().getDate() - 1);
    const [start_date_input, set_start_date_input] = useState(default_date);
    const [is_getting_self_service, set_is_getting_self_service] = useState(false);

    const [selected_row, set_selected_row] = useState(null);

    function on_device_name_input_change(e) {
        set_device_name_input(e.target.value);
    }

    function on_start_date_input_change(date) {
        set_start_date_input(date);
    }

    function get_self_service() {
        set_is_getting_self_service(true);

        API.get_self_service(device_name_input, start_date_input.toJSON())
            .then(
                (result) => {
                    console.log('Self service', result);

                    set_is_getting_self_service(false);
                    set_policies(result.policies);
                    set_self_service(result.self_service);
                },
                (error) => {
                    console.error(error);
                    // TODO: show the error to the user

                    set_is_getting_self_service(false);
                }
            )
    }

    return (
        <Fragment>
            {<Helmet>
                <title>Libra - Self Service</title>
            </Helmet>}
            <Col className='bg-dark text-bg-dark' md='3' >
                <Form onSubmit={e => { e.preventDefault(); get_self_service(); }}>
                    <Form.Group className="mb-3">
                        <Form.Label>Device Name</Form.Label>
                        <Form.Control type="text" placeholder="Device Name" value={device_name_input} onChange={on_device_name_input_change} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Time</Form.Label>
                        <DatePicker selected={start_date_input} onChange={on_start_date_input_change} />
                    </Form.Group>
                    <Button variant="primary" onClick={get_self_service}>
                        Show Self Service Requests {is_getting_self_service && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                    </Button>
                </Form>
            </Col>
            <Col className='mt-4' md='8'>
                <table className='table table-dark table-striped'>
                    <thead>
                        <tr>
                            <th scope='col'>Timestamp</th>
                            <th scope='col'>Device</th>
                            <th scope='col'>Status</th>
                            <th scope='col'>Control</th>
                            <th scope='col'>Policy</th>
                            <th scope='col'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(self_service).map(({id, creation_time, device_name, status, control, policy_id}) => 
                            <tr key={id} onClick={e => set_selected_row(id)} className={selected_row == id ? 'selected':''}>
                                <td>{creation_time}</td>
                                <td>{device_name}</td>
                                <td>{status}</td>
                                <td>{control}</td>
                                <td>{policies[policy_id]?.name}</td>
                                <td>
                                    <a className='external_link' href={`/self_service?id=${id}`} target='_blank' onClick={e => {e.stopPropagation();}}>
                                        <FontAwesomeIcon className='bg-dark text-bg-dark' icon={faExternalLink} />
                                    </a>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {(selected_row != null && <SelfServiceSidePanel row={self_service[selected_row]} policies={policies} onClose={() => set_selected_row(null)} />)}
            </Col>
        </Fragment>
    );
};


function SelfServiceSidePanel({row, policies, onClose}) {
    console.log('selected row', row);

    return (
      <Offcanvas show={true} onHide={onClose} placement="end">
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>Self-Service - {row.id}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <dl>
                <dt>ID</dt>
                <dd>{row.id}</dd>
                <dt>Creation Time</dt>
                <dd>{row.creation_time}</dd>
                <dt>Device</dt>
                <dd>{row.device_name}</dd>
                <dt>Status</dt>
                <dd>{row.status}</dd>
                <dt>Control</dt>
                <dd>{row.control}</dd>
                <dt>Policy</dt>
                <dd>{policies[row.policy_id]?.name}</dd>
                <dt>Approval Time</dt>
                <dd>{row.approval_time}</dd>
                <dt>Justification</dt>
                <dd>{row.justification}</dd>
                <dt>Events</dt>
                <dd>
                    <ul>
                        {row.events_to_approve.map(({event_data}, i) => <li key={i}>{event_data}</li>)}
                    </ul>
                </dd>
            </dl>
        </Offcanvas.Body>
      </Offcanvas>
    );
}