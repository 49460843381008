import React, { useState } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faShield } from '@fortawesome/free-solid-svg-icons';
import classNames from "classnames";

import { stableSort } from "utils/utils";


export default function Policies({height, policies, onSelect, onHover, hovered_policy_index, selected_policy_index}) {
    const sorted_policies = stableSort(policies, (a, b) => b.entities.length - a.entities.length);

    return (
        <ListGroup className="policies p-2" style={{'height': height-10}}>
            {sorted_policies.map(({id, name, entities, security_score}, index) => 
                <ListGroup.Item key={id} className={classNames('policy', {'active': index == hovered_policy_index || index == selected_policy_index})} onMouseEnter={e => onHover(index)} onMouseLeave={e => onHover(-1)} onClick={e => onSelect(index)}>
                    <span className='w-65 d-inline-block'>
                        {name}
                    </span>
                    <Badge className='float-end m-1' bg='primary' pill>
                        A+
                    </Badge>
                    {/*<Badge className='float-end m-1' bg='danger' pill>
                        {id}
                    </Badge>*/}
                    <Badge className='float-end m-1' bg='info'>{entities.length}
                        &nbsp;<FontAwesomeIcon icon={faDesktop} />
                    </Badge>
                    <Badge className='float-end m-1' bg='success' pill>
                        {parseInt(security_score * 100)}%
                        &nbsp;<FontAwesomeIcon icon={faShield} />
                    </Badge>
                </ListGroup.Item>
            )}
        </ListGroup>
    );
}