import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import Controls from 'components/Controls.jsx';
import DeviceAssignments from 'components/DeviceAssignments.jsx';
import PolicySidePanel from 'components/PolicySidePanel.jsx';
import API from 'utils/api';

import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { CONTROLS } from 'consts.js';

import { UserContext } from 'main.jsx';
import { Permissions } from 'enums.js';


export default function PoliciesEnrollmentPage() {
    const [error, set_error] = useState(null);
    const [is_loaded, set_is_loaded] = useState(false);
    const [migrating_policies, set_migrating_policies] = useState([]);

    const [selected_control, set_selected_control] = useState(''); // TODO: replace with url

    const [selected_policy, set_selected_policy] = useState(-1);

    const [is_getting_assignments, set_is_getting_assignments] = useState(false);
    const [group_name_input, set_group_name_input] = useState('');
    const [devices_assignments, set_devices_assignments] = useState(null);
    const [missing_devices, set_missing_devices] = useState(null);
    const [is_assigning, set_is_assigning] = useState(false);

    const {user} = useContext(UserContext);

    useEffect(() => {
        let cancelled = false;

        // TODO: remove
        API.get_migrating_policies()
        .then(
            (result) => {                
                if (!cancelled) {
                    set_migrating_policies(result.policies);
                    set_is_loaded(true);
                }
            },
            (error) => {
                if (!cancelled) {
                    set_is_loaded(true);
                    set_error(error);
                }
            }
        )

        return () => {
            cancelled = true;
        };
    }, []);


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!is_loaded) {
        return <div>Loading...</div>;
    } else {
        function get_assignments() {
            set_is_getting_assignments(true);

            API.get_group_assignments(selected_control, group_name_input)
                .then(
                    (result) => {
                        console.log('Group assignments', result);

                        set_is_getting_assignments(false);
                        set_devices_assignments(result.devices);
                        set_missing_devices(result.missing_devices);
                    },
                    (error) => {
                        console.error(error);
                        // TODO: show the error to the user

                        set_is_getting_assignments(false);
                    }
                )
        }

        function onSelectedControlChange(control) {
            set_selected_control(control);
            set_devices_assignments(null);
            set_missing_devices(null);
        }

        function on_assign_click(mode) {
            const device_ids = devices_assignments.map(device => device.id);
            API.assign_devices(selected_control, device_ids, mode)
                .then(
                    (result) => {
                        console.log('Assign devices', result);

                        set_is_assigning(false);
                        set_devices_assignments(result.devices);
                    },
                    (error) => {
                        console.error(error);
                        // TODO: show the error to the user
                    }
                )
            ;
        }

        function on_group_name_input_change(e) {
            set_group_name_input(e.target.value);
        }

        return (
            <Fragment>
                <Helmet>
                    <title>Libra - {selected_control ? `${selected_control} Policies Enrollment`: 'New Policies Enrollment'}</title>
                </Helmet>
                <Col className='bg-dark text-bg-dark' md='3' >
                    <Controls selected_control={selected_control} policies={migrating_policies} filter_policies_status="migrating_to" /* TODO: use a const/enum for that value */ controls={CONTROLS} onSelect={onSelectedControlChange} />
                </Col>
                <Col className='mt-4' md='8'>
                    {(selected_control && <Fragment>
                        <Form onSubmit={e => { e.preventDefault(); get_assignments(); }}>
                            <Form.Group className="mb-3">
                                <Form.Label>Device / Group name</Form.Label>
                                <Form.Control type="text" placeholder="Device / Group name" value={group_name_input} onChange={on_group_name_input_change} />
                            </Form.Group>
                            <Button variant="primary" onClick={get_assignments}>
                                Show Assignment {is_getting_assignments && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                            </Button>
                        </Form>
                        
                        {devices_assignments && <Fragment>
                            <h4>{devices_assignments.length} {devices_assignments.length > 1 ? 'devices' : 'device'} found</h4>
                            <Button variant="outline-success" onClick={e=>on_assign_click('audit')} disabled={!user.permissions.includes(Permissions.CREATE_POLICIES)}>
                                Assign Audit {is_assigning && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                            </Button>
                            <Button variant="outline-danger" onClick={e=>on_assign_click('block')} disabled={!user.permissions.includes(Permissions.CREATE_POLICIES)}>
                                Assign Block {is_assigning && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                            </Button>
                            <DeviceAssignments devices={devices_assignments} policies={migrating_policies[selected_control]} />
                        </Fragment>}
                        {missing_devices && <Fragment> {/* TODO: change or delete */}
                            <h4>{missing_devices.length} {missing_devices.length > 1 ? 'devices' : 'device'} not found (no EDR?)</h4>
                            <ul>
                                {missing_devices.map(device => <li key={device.aad_id}>{device.name} - {device.aad_id}</li>)}
                            </ul>
                        </Fragment>}
                    </Fragment>)}
                </Col>
                {(selected_policy >= 0 && <PolicySidePanel control={selected_control} policy={migrating_policies[selected_control][selected_policy]} onClose={() => set_selected_policy(-1)} />)}
            </Fragment>
        );
    }
};