import React, { useState, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

import API from 'utils/api';
import { stableSort } from "utils/utils";

import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';



export default function FeaturePage() {
    const [devices, set_devices] = useState({});
    const [all_features, set_all_features] = useState([]);
    const [feature_changes, set_feature_changes] = useState({});
    const [logs_changes, set_logs_changes] = useState({});
    const [db_changes, set_db_changes] = useState({});

    const [device_name_input, set_device_name_input] = useState('');
    const [is_getting_devices, set_is_getting_devices] = useState(false);
    const [is_saving, set_is_saving] = useState(false);

    function on_device_name_input_change(e) {
        set_device_name_input(e.target.value);
    }

    function get_devices() {
        set_is_getting_devices(true);

        API.get_feature_devices(device_name_input)
            .then(
                (result) => {
                    console.log('Devices', result);

                    set_is_getting_devices(false);
                    set_devices(result.devices);
                    set_all_features(result.all_features);
                },
                (error) => {
                    console.error(error);
                    // TODO: show the error to the user

                    set_is_getting_devices(false);
                }
            )
    }

    function on_feature_change(id, feature) {
        set_feature_changes({...feature_changes, [id]: {...feature_changes[id], [feature]: !feature_changes[id]?.[feature]}});
    }

    function on_logs_change(agent, ask_for_logs) {
        // If the new value is equal to the original value from the server, remove the change from the changes object
        if (ask_for_logs == (agent.ask_for_logs || 0)) {
            const new_logs_changes = {...logs_changes};
            delete new_logs_changes[agent.id];
            set_logs_changes(new_logs_changes);
        }
        else {
            set_logs_changes({...logs_changes, [agent.id]: ask_for_logs});
        }
    }

    function on_db_change(agent, ask_for_db) {
        // If the new value is equal to the original value from the server, remove the change from the changes object
        if (ask_for_db == (agent.ask_for_db || false)) {
            const new_db_changes = {...db_changes};
            delete new_db_changes[agent.id];
            set_db_changes(new_db_changes);
        }
        else {
            set_db_changes({...db_changes, [agent.id]: ask_for_db});
        }
    }

    function count_changes() {
        return Object.values(feature_changes).reduce((accumulator, currentValue) => {
            return accumulator + Object.values(currentValue).reduce((accumulator, currentValue) => {return accumulator + currentValue}, 0);
        }, 0) + Object.keys(logs_changes).length + Object.keys(db_changes).length;
    }

    function has_changes() {
        return count_changes() > 0;
    }

    function save_changes() {
        set_is_saving(true);

        API.set_feature_changes(feature_changes, logs_changes, db_changes)
            .then(
                (result) => {
                    console.log('Set feature changes', result);

                    set_is_saving(false);
                    set_feature_changes({});
                    set_logs_changes({});
                    set_db_changes({});
                    set_devices({...devices, ...result.devices});
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    return (
        <Fragment>
            {<Helmet>
                <title>Libra - Features</title>
            </Helmet>}
            <Col className='bg-dark text-bg-dark' md='3' >
                <Form onSubmit={e => { e.preventDefault(); get_devices(); }}>
                    <Form.Group className="mb-3">
                        <Form.Label>Device Name</Form.Label>
                        <Form.Control type="text" placeholder="Device Name" value={device_name_input} onChange={on_device_name_input_change} />
                    </Form.Group>
                    <Button variant="primary" onClick={get_devices}>
                        Show Devices {is_getting_devices && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                    </Button>
                </Form>
            </Col>
            <Col className='mt-4' md='8'>
                {/* if had any changes, show a button to save them */}
                <Button variant="success" onClick={save_changes} disabled={!has_changes()}>
                    Save changes ({count_changes()}) {is_saving && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                </Button>
                {/* TODO: add a way to set/unset the feature for all devices at once */}
                <table className='table table-dark table-striped'>
                    <thead>
                        <tr>
                            <th scope='col'>ID</th>
                            <th scope='col'>Device</th>
                            <th scope='col'>Agent</th>
                            <th scope='col'>Identifiers</th>
                            <th scope='col'>Features</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(devices).map(({id, name, agent, ids, features}) => 
                            <tr key={id}>
                                <td>{id}</td>
                                <td>{name}</td>
                                <td>
                                    {agent !== null && (
                                        <dl>
                                            <dt>ID</dt>
                                            <dd>{agent.id}</dd>
                                            <dt>Version</dt>
                                            <dd>{agent.version}</dd>
                                            <dt>Install Time</dt>
                                            <dd>{agent.install_time}</dd>
                                            <dt>Last Seen</dt>
                                            <dd>{agent.last_seen}</dd>
                                            <dt>Is Installed</dt>
                                            <dd>{agent.is_installed}</dd>
                                            <dt>Ask for Logs</dt>
                                            <dd>
                                                <input type="number" min={0} value={logs_changes[agent.id] || agent.ask_for_logs || 0} onChange={e => {on_logs_change(agent, parseInt(e.target.value))}} />
                                            </dd>
                                            <dt>Ask for DB</dt>
                                            <dd>
                                                <input type="checkbox" checked={db_changes[agent.id] || agent.ask_for_db || false} onChange={e => {on_db_change(agent, e.target.checked)}} />
                                            </dd>
                                        </dl>
                                    )}
                                </td>
                                <td>
                                    <dl>
                                        {stableSort(ids, (a, b) => b.timestamp - a.timestamp).map(({type, value, timestamp}) => 
                                            <Fragment key={type+value+timestamp}>
                                                <dt>{type}</dt>
                                                <dd>{value}</dd>
                                                <dd>{timestamp}</dd>
                                            </Fragment>
                                        )}
                                    </dl>
                                </td>
                                <td>
                                    <ul>
                                        {all_features.map(feature => 
                                            <li key={feature}>
                                                <label>
                                                    <input type="checkbox" checked={(!feature_changes[id]?.[feature] != !features.includes(feature))} onChange={e => {on_feature_change(id, feature, e.target.checked)}} />
                                                    {feature}
                                                </label>
                                            </li>
                                        )}
                                    </ul>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Col>
        </Fragment>
    );
};


