import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

export default function NotFoundPage() {
    return (
        <Fragment>
            <Helmet>
                <title>Libra - Not Found</title>
            </Helmet>
            <h1>Not Found</h1>
        </Fragment>
    );
}