import React, { Fragment, useRef } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheck, faShield, faTriangleExclamation, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';


import { stableSort, useWindowSize } from "utils/utils";
import { Col, Row } from "react-bootstrap";


export default function DeviceAssignments({devices, policies}) {
    const sorted_devices = stableSort(devices, (a, b) => b.name - a.name);
    const startRef = useRef(null);

    const [window_width, window_height] = useWindowSize();

    const policies_by_id = {};
    policies.forEach(policy => {
        policies_by_id[policy.id] = policy;
    });

    const ref_top = startRef.current?.getBoundingClientRect()?.y;
    const style = ref_top ? {'height': window_height - ref_top} : {};

    return (
        <Fragment>
            <div ref={startRef}></div>
            <ListGroup className="devices_assignments p-2" style={style}>
                {sorted_devices.map(({id, name, proposed_assignment, current_assignment, last_manual_approval}, index) => {
                    const proposed_policy = policies_by_id[proposed_assignment?.policy_id];
                    const current_policy = policies_by_id[current_assignment?.policy_id];
                    
                    const is_already_assigned = proposed_policy == null && current_policy != null;
                    const no_proposed_assignment = proposed_policy == null && current_policy == null;
                    const [left_policy, right_policy] = is_already_assigned ? [proposed_policy, current_policy] : [current_policy, proposed_policy];

                    return (
                        <ListGroup.Item key={name} className='device_assignment'>
                            <h6 className='w-65 d-inline-block'>
                                {name}
                            </h6>
                            <Row>
                                <Col>
                                    {left_policy && <Fragment>
                                        {left_policy.name}
                                        <Badge className='float-end m-1' bg='success' pill>
                                            {parseInt(left_policy.security_score * 100)}%
                                            &nbsp;<FontAwesomeIcon icon={faShield} />
                                        </Badge>
                                        <Badge className='float-end m-1' bg='danger' pill>
                                            {left_policy.id}
                                        </Badge>
                                        {(left_policy == current_policy && current_assignment?.status == 'assigned') && <Badge className='float-end m-1' bg='success' pill>
                                            &nbsp;<FontAwesomeIcon icon={faCheck} />
                                        </Badge>}
                                    </Fragment>}
                                </Col>
                                <Col>
                                    {(() => {
                                        if (is_already_assigned) {
                                            return (<Fragment>
                                                {current_assignment.status == 'assigned' ? 'Assigned' : 'Pending'} &nbsp;
                                                <FontAwesomeIcon icon={faCheck} />
                                            </Fragment>);
                                        }
                                        else if (no_proposed_assignment) {
                                            if (last_manual_approval != null) {
                                                return (<Fragment>
                                                    Policy temporary disabled &nbsp;
                                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                                    <div>{last_manual_approval}</div>
                                                </Fragment>);
                                            }

                                            return (<Fragment>
                                                No proposed assignment &nbsp;
                                                <FontAwesomeIcon icon={faTriangleExclamation} />
                                            </Fragment>);
                                        }
                                        else {
                                            return (<Fragment>
                                                Should be assigned to &nbsp;
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </Fragment>);
                                        }
                                    })()}
                                </Col>
                                <Col>
                                    {right_policy && <Fragment>
                                        {right_policy.name}
                                        <Badge className='float-end m-1' bg='success' pill>
                                            {parseInt(right_policy.security_score * 100)}%
                                            &nbsp;<FontAwesomeIcon icon={faShield} />
                                        </Badge>
                                        <Badge className='float-end m-1' bg='danger' pill>
                                            {right_policy.id}
                                        </Badge>
                                        {current_assignment && <Badge className='float-end m-1' bg='info' pill>
                                            {current_assignment.mode}&nbsp;<FontAwesomeIcon icon={current_assignment.mode == 'audit' ? faUnlock : faLock} />
                                        </Badge>}
                                        {(right_policy == current_policy && current_assignment?.status == 'assigned') && <Badge className='float-end m-1' bg='success' pill>
                                            &nbsp;<FontAwesomeIcon icon={faCheck} />
                                        </Badge>}
                                    </Fragment>}
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </Fragment>
    );
}