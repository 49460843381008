import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShield } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';


export default function Controls({onHover, onSelect, selected_control, policies, filter_policies_status, controls}) {
    const calc_control_score = (id) => {
        // Filter policies by status (if provided)
        const relevant_policies = (filter_policies_status ? policies[id].filter(p => p.status == filter_policies_status) : policies[id]);

        // Calc the sum of number of entities multiply by the security score of each policy
        const total_entities = relevant_policies.reduce((acc, p) => acc + p.entities.length, 0);
        const total_score = relevant_policies.map(p => p.security_score * p.entities.length).reduce((acc, val) => acc + val, 0);

        return total_score / total_entities;
    }
    
    return (
        <div>
            {controls.map(({name, id}) =>
                (id in policies) && <Button key={id} className={classNames('m-2 p-1 ps-3 bg-light text-bg-light rounded-3 control', {'selected': selected_control == id})} onMouseEnter={e => onHover && onHover(id)} onMouseLeave={e => onHover && onHover('')} onClick={e => onSelect && onSelect(id)}>
                    {name}
                    <Badge className='float-right m-1' bg='success' pill aria-details={parseInt(calc_control_score(id) * 10000) / 100}>
                        {parseInt(calc_control_score(id) * 100)}%
                        &nbsp;<FontAwesomeIcon icon={faShield} />
                    </Badge>
                </Button>
            )}
        </div>
    );
}