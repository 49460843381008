import { useLayoutEffect, useState } from 'react';


export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export function min(...values) {
    let res = values[0];
    for (const value of values) {
        if (res > value) {
            res = value;
        }
    }
      
    return res;
}

export function max(...values) {
    let res = values[0];
    for (const value of values) {
        if (res < value) {
            res = value;
        }
    }
      
    return res;
}

export function abs(value) {
    return (value >= 0) ? value : -value;
}

export function round_digits(value, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
}

export function stableSort(arr, cmp) {
    cmp = !!cmp ? cmp : (a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    };
    const stabilizedThis = arr.map((el, index) => [el, index]);
    const stableCmp = (a, b) => {
        const order = cmp(a[0], b[0]);
        if (order != 0) return order;
        return a[1] - b[1];
    }
    stabilizedThis.sort(stableCmp);
    for (let i=0; i<arr.length; i++) {
        arr[i] = stabilizedThis[i][0];
    }
    return arr;
}