import React, { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { get_pages } from 'pages/pages.jsx';
import { UserContext } from 'main.jsx';
import API from 'utils/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';


export default function PageLayout() {
    const location = useLocation();

    const {user, setUser} = useContext(UserContext);

    return (
        <Container fluid className='p-0 min-vh-100 d-flex flex-column'>
            <Navbar className='bg-dark text-bg-dark'>
                <Navbar.Brand href="/" className='logo'>Libra</Navbar.Brand>
                {/* TODO: show the helmet title of the page */}
                <Nav className="justify-content-end flex-grow-1 pe-3">
                    {(user !== null) && get_pages(user).map(({path, title}) => {
                        const actual_path = path.split(':')[0];

                        return (
                            <LinkContainer key={path} to={actual_path}>
                                <Nav.Link className={classNames('m-2 p-1 bg-light text-bg-light rounded-3', {'selected': location.pathname == actual_path || (actual_path != '/' && location.pathname.startsWith(actual_path))})}>
                                    {title}
                                </Nav.Link>
                            </LinkContainer>
                        );
                    })}

                    {(user !== null) && 
                        <NavDropdown title={<FontAwesomeIcon icon={faUser} />} className='rounded-3' drop='start' id="user_dropdown">
                            <NavDropdown.Item disabled={true}>
                                {user.username}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={e => {API.logout(); setUser(null);}}>
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    }
                </Nav>
            </Navbar>

            <Row className='flex-grow-1'>
                <Outlet />
            </Row>

            {/*<Row>
                Footer
            </Row>*/}
        </Container>
    );
}