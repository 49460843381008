import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import ScoreGraph from 'components/ScoreGraph.jsx';
import API from 'utils/api';

export default function DashboardPage() {
    const [error, set_error] = useState(null);
    const [is_loaded, set_is_loaded] = useState(false);

    const [score_series, set_score_series] = useState([]);
    const [mitre_matrix_weights, set_mitre_matrix_weights] = useState({});

    useEffect(() => {
        let cancelled = false;

        // TODO: remove
        API.score_over_time()
        .then(
            (result) => {                
                if (!cancelled) {
                    set_score_series(result.score);
                    set_mitre_matrix_weights(result.theme_weights);
                    set_is_loaded(true);
                }
            },
            (error) => {
                if (!cancelled) {
                    set_is_loaded(true);
                    set_error(error);
                }
            }
        )

        return () => {
            cancelled = true;
        };
    }, []);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!is_loaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <Fragment>
                <Helmet>
                    <title>Libra - Dashboard</title>
                </Helmet>
                <ScoreGraph score_series={score_series} />
            </Fragment>
        );
    }
}