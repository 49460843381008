export default class API {
    static post(url, data) {
        return fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(res => res.json());
    }

    static get(url) {
        return fetch(url)
            .then(res => res.json());
    }

    static get_pending_policies() {
        return API.get("/api/pending_policies");
    }

    static get_migrating_policies() {
        return API.get("/api/migrating_policies");
    }

    static generate_policies(control_id, minimum_group_size) {
        return API.post("/api/generate_policies", {control_id, minimum_group_size});
    }

    static approve_policies(control_id) {
        return API.post("/api/approve_policies", {control_id});
    }

    static get_group_assignments(control_id, group_name_prefix) {
        return API.post("/api/get_group_assignments", {control_id, group_name_prefix});
    }

    static assign_devices(control_id, device_ids, mode) {
        return API.post("/api/assign_devices", {control_id, device_ids, mode});
    }

    static score_over_time() {
        return API.get("/api/score_over_time");
    }

    static get_log(device_name, start_date) {
        return API.get(`/api/get_log?device_name=${device_name}&start_date=${start_date}`);
    }

    static get_self_service(device_name, start_date) {
        return API.get(`/api/get_self_service?device_name=${device_name}&start_date=${start_date}`);
    }

    static get_feature_devices(device_name) {
        return API.get(`/api/get_feature_devices?device_name=${device_name}`);
    }

    static set_feature_changes(feature_changes, logs_changes, db_changes) {
        return API.post("/api/set_feature_changes", {feature_changes, logs_changes, db_changes});
    }

    static login(username, password) {
        return API.post("/api/login", {username, password});
    }

    static logout() {
        return API.post("/api/logout");
    }
}
