// TODO: take from the server
export const CONTROLS = [
    {
        name: 'Host Firewall',
        id: 'firewall',
    },
    {
        name: 'Windows Defender Application Control',
        id: 'wdac',
    },
    {
        name: 'ASR Block Office Applications From Creating Child Processes',
        id: 'asr_block_office_child_process',
    },
    {
        name: 'ASR Block Office Communication Applications From Creating Child Processes',
        id: 'asr_block_office_communication_child_process',
    },
    {
        name: 'ASR Block Adobe Reader Applications From Creating Child Processes',
        id: 'asr_block_adobe_child_process',
    },
];