import React, { useState, Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import Offcanvas from 'react-bootstrap/Offcanvas';

import API from 'utils/api';

import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';


export default function LogPage() {
    const [policies, set_policies] = useState({});
    const [log, set_log] = useState([]);

    const [device_name_input, set_device_name_input] = useState('');
    const default_date = new Date();
    default_date.setDate(new Date().getDate() - 1);
    const [start_date_input, set_start_date_input] = useState(default_date);
    const [is_getting_log, set_is_getting_log] = useState(false);

    const [selected_row, set_selected_row] = useState(null);

    function on_device_name_input_change(e) {
        set_device_name_input(e.target.value);
    }

    function on_start_date_input_change(date) {
        set_start_date_input(date);
    }

    function get_log() {
        set_is_getting_log(true);

        API.get_log(device_name_input, start_date_input.toJSON())
            .then(
                (result) => {
                    console.log('Log', result);

                    set_is_getting_log(false);
                    set_policies(result.policies);
                    set_log(result.log.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)));
                },
                (error) => {
                    console.error(error);
                    // TODO: show the error to the user

                    set_is_getting_log(false);
                }
            )
    }

    const row_by_id =useMemo(() => {
        return Object.assign({}, ...log.map((row) => ({[row.id]: row})));
    }, [log]);

    return (
        <Fragment>
            {<Helmet>
                <title>Libra - Log</title>
            </Helmet>}
            <Col className='bg-dark text-bg-dark' md='3' >
                <Form onSubmit={e => { e.preventDefault(); get_log(); }}>
                    <Form.Group className="mb-3">
                        <Form.Label>Device Name</Form.Label>
                        <Form.Control type="text" placeholder="Device Name" value={device_name_input} onChange={on_device_name_input_change} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Time</Form.Label>
                        <DatePicker selected={start_date_input} onChange={on_start_date_input_change} />
                    </Form.Group>
                    <Button variant="primary" onClick={get_log}>
                        Show Log {is_getting_log && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                    </Button>
                </Form>
            </Col>
            <Col className='mt-4' md='8'>
                <table className='table table-dark table-striped'>
                    <thead>
                        <tr>
                            <th scope='col'>Timestamp</th>
                            <th scope='col'>Device</th>
                            <th scope='col'>Event</th>
                            <th scope='col'>Control</th>
                            <th scope='col'>Policy</th>
                        </tr>
                    </thead>
                    <tbody>
                        {log.map(({id, timestamp, device_name, event, control, policy_id, additional_fields}) => 
                            <tr key={id} onClick={e => set_selected_row(id)} className={selected_row == id ? 'selected':''}>
                                <td>{timestamp}</td>
                                <td>{device_name}</td>
                                <td>{event}</td>
                                <td>{control}</td>
                                <td>{policies[policy_id]?.name}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {(selected_row != null && <LogSidePanel row={row_by_id[selected_row]} policies={policies} onClose={() => set_selected_row(null)} />)}
            </Col>
        </Fragment>
    );
};


function LogSidePanel({row, policies, onClose}) {
    console.log('selected row', row);

    return (
      <Offcanvas show={true} onHide={onClose} placement="end">
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>Log Event {row.id}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <dl>
                <dt>ID</dt>
                <dd>{row.id}</dd>
                <dt>Timestamp</dt>
                <dd>{row.timestamp}</dd>
                <dt>Device</dt>
                <dd>{row.device_name}</dd>
                <dt>Event</dt>
                <dd>{row.event}</dd>
                <dt>Control</dt>
                <dd>{row.control}</dd>
                <dt>Policy</dt>
                <dd>{policies[row.policy_id]?.name}</dd>
                <dt>Additional Fields</dt>
                <dd>
                    <ul>
                        {Object.entries(row.additional_fields).map(([key, value]) => <li key={key}>{key}: {value?.toString()}</li>)}
                    </ul>
                </dd>
            </dl>
        </Offcanvas.Body>
      </Offcanvas>
    );
}